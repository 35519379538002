<template>
  <v-app color="white" class="py-0">
    <v-dialog width="700" v-model="settings">
      <v-card class="pa-5">
        <v-combobox
          outlined
          return-object
          v-model="doctorsInSelect"
          @change="selectDoctors"
          single-line
          label="Select doctors"
          chips
          :items="doctors"
          item-text="name"
          clearable
          deletable-chips
          multiple
        >
        </v-combobox>
        <v-textarea v-model="ticker" outlined label="Ticker Message">
        </v-textarea>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="settings = false">Cancel</v-btn>
          <v-btn color="primary" @click="saveTicker">SAVE</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-hover>
      <template v-slot:default="{ hover }">
        <v-app-bar dense app flat>
          <v-row no-gutters class="display-2 align-center" style="width=100%;">
            <v-col class="d-flex align-center" cols="1">
              <img
                style="z-index:-1; position: absolute;margin-left:10px;"
                class="clickable white"
                justify="self-start"
                height="35px"
                :src="locationDetail.logo ? 'data:image/jpg;base64,'+locationDetail.logo : locationDetail.logoUrl"
              />
            </v-col>
            <v-col
              v-if="$route.name === 'display'"
              class="d-flex justify-start display-1 font-weight-bold"
              style="color:#e95b2d"
              cols="2"
            >
              ROOM
            </v-col>
            <v-col
              v-if="$route.name === 'display'"
              class="d-flex justify-start display-1 font-weight-bold"
              style="color:#e95b2d"
              cols="5"
            >
              DOCTOR
            </v-col>
            <v-col
              v-if="$route.name === 'display'"
              class="d-flex justify-center display-1 font-weight-bold"
              style="color:#e95b2d"
              cols="4"
            >
              SLOT/TOKEN
            </v-col>
          </v-row>
          <template
            v-if="$route.name === 'display-split'"
            class="px-0 mx-0"
            v-slot:extension
          >
            <v-row style="width=100%;" no-gutters>
              <v-col
                style="color:#e95b2d"
                class="pl-5 font-weight-bold headline"
                cols="3"
                >DOCTOR</v-col
              >
              <v-col
                style="color:#e95b2d"
                class="font-weight-bold d-flex headline justify-center"
                cols="3"
                >SLOT/TOKEN</v-col
              >
              <v-col
                style="color:#e95b2d"
                class="pl-5 font-weight-bold headline"
                cols="3"
                >DOCTOR</v-col
              >
              <v-col
                style="color:#e95b2d"
                class="font-weight-bold d-flex headline justify-center"
                cols="3"
                >SLOT/TOKEN</v-col
              >
            </v-row>
          </template>
          <v-fade-transition>
            <v-overlay
              v-if="hover"
              class="justify-end"
              absolute
              color="#db9704"
            >
              <v-btn icon class="mr-3" @click="dialog = true"
                ><v-icon x-large @click="settings = true"
                  >settings</v-icon
                ></v-btn
              >
            </v-overlay>
          </v-fade-transition>
        </v-app-bar>
      </template>
    </v-hover>
    <v-main class="white">
      <v-container fluid class="fill-height ma-0 pa-0">
        <nuxt />
      </v-container>
    </v-main>
    <v-footer app color="white" class="align-center py-0 px-2">
      <v-row align="center" no-gutters>
        <v-col cols="2" class="pa-0 mb-1">
          <span class="align-center d-flex">
            <v-icon color="#2680eb">brightness_1</v-icon>
            &nbsp;Scheduled tokens
          </span>
          <span class="align-center d-flex">
            <v-icon color="green">brightness_1</v-icon>
            &nbsp;Walk-in tokens
          </span>
        </v-col>
        <v-col cols="9" class="py-0">
          <marquee class="headline">{{ tickerMessage }}</marquee>
        </v-col>
        <v-col cols="1" class="align-center d-flex justify-end py-0">
          <span class="caption">&nbsp;</span>
          <img
            @click="toggleFullscreen"
            class="clickable white"
            width="100px"
            src="../assets/images/bestdoclogo.svg"
            alt="BESTDOC"
          />
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>
<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
export default {
  data() {
    return {
      settings: false,
      doctorsInSelect: [],
      ticker: ''
    }
  },
  computed: {
    ...mapState('display', ['doctors', 'selectedDoctors', 'tickerMessage']),
    ...mapGetters('display', ['doctorsOnDisplay', 'locationDetail']),
    doctorIds() {
      return this.selectedDoctors.map(doctor => doctor.id).join()
    }
  },
  mounted() {
    if(this.$route.params.id){
      this.loadData()
    }
  },

  watch: {
    tickerMessage(val) {
      this.$router.replace({
        name: this.$route.name,
        params:{id : this.locationDetail.key},
        query: {
          tickerMessage: this.tickerMessage,
          doctors: this.doctorIds
        }
      })
    },
    doctorsOnDisplay(val) {
      if (val.length > 4 && this.$route.name === 'display') {
        this.$router.replace({
          name: 'display-split',
          params:{id : this.locationDetail.key},
          query: {
            tickerMessage: this.tickerMessage,
            doctors: this.doctorIds
          }
        })
      } else if (val.length <= 4 && this.$route.name === 'display-split') {
        this.$router.replace({
          name: 'display',
          params:{id : this.locationDetail.key},
          query: {
            tickerMessage: this.tickerMessage,
            doctors: this.doctorIds
          }
        })
      } else {
        let query = this.$route.query
        if(query.tickerMessage != this.tickerMessage &&  query.doctors != this.doctorIds){
        this.$router.replace({
          name: this.$route.name,
          params:{id : this.locationDetail.key},
          query: {
            tickerMessage: this.tickerMessage,
            doctors: this.doctorIds
          }
        })
        }
      }
    }
  },
  methods: {
    ...mapMutations('display', ['SET_SELECTED_DOCTORS', 'SET_TICKER_MESSAGE']),
    ...mapActions('display', ['fetchDoctors', 'fetchCheckedInDoctorIds','init']),
    saveTicker() {
      this.SET_TICKER_MESSAGE(this.ticker)
      this.settings = false
    },
    selectDoctors(val) {
      this.SET_SELECTED_DOCTORS(val)
    },
    async loadData() {
      await this.init(this.$route.params.id)
      await this.fetchDoctors()
      await this.fetchCheckedInDoctorIds()
      if (!this.$route.query.doctors) {
        if (this.$route.name === 'display-split') {
          this.$router.replace({
            name: 'display'
          })
        }
        return
      }
      const doctorIds = this.$route.query.doctors
        .split(',')
        .map(doctor => parseInt(doctor))

      const tickerMessage = this.$route.query.tickerMessage
      if (tickerMessage) {
        this.SET_TICKER_MESSAGE(tickerMessage)
      }

      let doctorsInURL = []
      for (let doctor of this.doctors) {
        if (doctorIds.indexOf(doctor.id) !== -1) {
          doctorsInURL.push(doctor)
        }
      }
      this.doctorsInSelect = doctorsInURL
      this.SET_SELECTED_DOCTORS(doctorsInURL)
    },
    toggleFullscreen() {
      const elem = document.documentElement
      if (
        document.fullscreenEnabled ||
        document.webkitFullscreenEnabled ||
        document.mozFullScreenEnabled ||
        document.msFullscreenEnabled
      ) {
        if (!this.isFullscreen) {
          if (elem.requestFullscreen) {
            elem.requestFullscreen()
            this.isFullscreen = true
            return
          }
          if (elem.webkitRequestFullscreen) {
            elem.webkitRequestFullscreen()
            this.isFullscreen = true
            return
          }
          if (elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen()
            this.isFullscreen = true
            return
          }
          if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen()
            this.isFullscreen = true
          }
        } else {
          if (document.exitFullscreen) {
            document.exitFullscreen()
            this.isFullscreen = false
            return
          }
          if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen()
            this.isFullscreen = false
            return
          }
          if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen()
            this.isFullscreen = false
            return
          }
          if (document.msExitFullscreen) {
            document.msExitFullscreen()
            this.isFullscreen = false
          }
        }
      }
    }
  }
}
</script>
<style scoped>
.v-app-bar /deep/ .v-toolbar__content {
  padding: 4px 0 !important;
}
.v-app-bar /deep/ .v-toolbar__extension {
  padding: 4px 0 !important;
}
</style>
